$('.btn-mobile-controls[aria-label="Menu"]').click(function(){
	function toggleBtn(){
		var expanded = $('.btn-mobile-controls[aria-label="Menu"]').attr('aria-expanded') == 'true' ? true : false;
		var newExpanded = !expanded;

		$('.btn-mobile-controls[aria-label="Menu"]').attr('aria-expanded', newExpanded);
		$('.btn-mobile-controls[aria-label="Menu"]').blur();
	}

	var bgMenu = document.createElement('div');

	toggleBtn();

	$(bgMenu).addClass('bg-menu');

	$(bgMenu).click(function(){
		$('.main-menu').removeClass('open');
		$('html,body').removeClass('lock-scroll');

		toggleBtn();

		$(this).fadeOut('fast', function(){
			$(this).remove();
		})
	});

	document.getElementById('app').appendChild(bgMenu);

	$('.main-menu').addClass('open');
	$('html,body').addClass('lock-scroll');
});

var spyMobileControls = (function(){
	function toggleFix(){
		var windowPosition = $(window).scrollTop();
		var offset = $('.contatos-topo').outerHeight();

		if(windowPosition >= offset){
			$('.mobile-controls').css('top', (windowPosition - offset)+'px')
		}
		else{
			$('.mobile-controls').removeAttr('style');
		}
	}

	$(window).scroll(function(){
		toggleFix();
	});

	toggleFix();
})();

$('[aria-label="Buscas"]').click(function(){
	var app = document.querySelector('#app');
	var bgSearch = document.createElement('div');

	$(bgSearch).addClass('bg-search');

	$(bgSearch).click(function(){
		$('.buscas-topo').removeClass('open');
		$('html,body').removeClass('lock-scroll');
		
		$(this).fadeOut('fast', function(){
			$(this).remove();
		});
	});

	$('.buscas-topo').addClass('open');
	$('html,body').addClass('lock-scroll');

	app.appendChild(bgSearch);
});