var _Alerta = {
	visible : false,
	variant : 'success',
	message : '',
	location: 'bottom'
};

$('#app').append('<div class="alerta top" id="system-alerts" style="display:none"></div>');

var Alerta =  new Proxy(_Alerta, {
	set : function (obj, prop, val) {
		obj[prop] = val;

		var alerta = $('#system-alerts');

		if(obj['visible'] == true){
			alerta.css('display', 'block')
		}

		else if (obj['visible'] == false){
			alerta.css('display', 'none')
		}

		if (prop == 'variant'){
			alerta.removeClass('danger success info warning light dark');
			alerta.addClass(val);
		}

		if (prop == 'message'){
			alerta.text(obj['message']);
		}

		if (prop == 'location'){
			alerta.text(obj['location']);
		}
	}
});

$('#system-alerts').click(function(){
	Alerta.visible = false;
});