$.fn.carouselResponsive = function(){
	let jaResize = false;

	return this.each(function(index, el){
		let responsive = {};

		responsive.xs = $(this).data('xs') || 1;
		responsive.sm = $(this).data('sm') || responsive.xs;
		responsive.md = $(this).data('md') || responsive.sm;
		responsive.lg = $(this).data('lg') || responsive.md;
		responsive.xl = $(this).data('xl') || responsive.lg;

		let carousel 	= $(this);
		let inner 		= $(this).find('.carousel-inner');
		let child 		= inner.children();
		let indicators	= carousel.find('.carousel-indicators');
		let id 			= $(this).attr('id');

		if(!id){
			carousel.attr('id', 'responsive-bs-carousel-'+index);

			id = 'responsive-bs-carousel-'+index;
		}

		function wrapCarousel(){
			inner.find('.carousel-inner > .row > *').unwrap('.row');
			inner.find('.carousel-inner > *').unwrap('.carousel-item');
			indicators.html('');

			for(let i=0; i < child.length; i++){
				carousel.find('.carousel-inner > *')
					.slice(i , (i, responsive[midiaSize]))
					.wrapAll('<div class="carousel-item"></div>')
					.wrapAll('<div class="row"></div>')
			}

			inner.find('.carousel-item:first-child').addClass('active');

			inner.find('.carousel-item').each(function(index, el){
				let indicator = `<li data-target="#${id}" data-slide-to="${index}" ${index == 0 ? 'class="active"' : ''}></li>`;

				indicators.append(indicator);
			});
		}

		wrapCarousel();

		$(window).resize(function(){
			if(jaResize) return;

			setTimeout(function(){
				jaResize = false;
			}, 100);

			wrapCarousel();
		})

	});
}

$('.carousel-responsive').carouselResponsive();

$('.carousel[data-interval]').each(function(){
	var slider 		= $(this);
	var interval 	= slider.data('interval');

	slider.carousel({
		interval : interval
	});

	setTimeout(function(){
		slider.carousel('next')
	}, interval);
});