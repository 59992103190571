$('[data-toggle="categorias"]').click(function(){
	var target = $(this).data('target');
	var expanded = $(this).attr('aria-expanded');
	var btn = $(this);
	var app = document.querySelector('#app');

	function toggleCategorias(){
		$(target).collapse('toggle');

		if (expanded == 'false'){
			btn.attr('aria-expanded', 'true');
		}

		if(expanded == 'true'){
			btn.attr('aria-expanded', 'false');
		}
	}

	switch (midiaSize){
		case 'xs':
			toggleCategorias()
		break;
		case 'sm':
			toggleCategorias()
		break;
		case 'md':

			$(target).addClass('open');

			var bgCategorias = document.createElement('div');

			$(bgCategorias).addClass('bg-categorias-md');

			$(bgCategorias).click(function(){
				$(target).removeClass('open');

				$(this).fadeOut(400, function(){
					$(this).remove();
				})
			});

			app.appendChild(bgCategorias);

		break;
		default:
			return false
		break;
	}
});