$('[data-toggle="qtde"]').click(function(){
	var target 	=  $(this).data('target');
	var fn 		=  $(this).data('fn');

	var val = parseInt($(target).val());

	switch (fn){
		case 'minus':
			if(val > 1){
				$(target).val(val-1);
			}
		break;
		case 'plus':
			$(target).val(val+1);
		break;
	}
});