class Componentes {
	constructor(){
		this.componentes = {};
	}

	define(elemento, Compositor){
		let els = document.querySelectorAll(elemento);

		if(els){
			this.componentes[elemento] = {
				seletor: elemento,
				compositor: Compositor,
				list: []
			};

			for(let el in els){
				this.componentes[elemento].list.push(new Compositor(els[el]));
			}
		}
	}
}

let elementosCustom = new Componentes();

var __midiaSize = (function(){
	window.midiaSize 	= 'xs';
	var jaCalcMidia 	= false;

	function verifyMidia(){
		// var w = screen.width;
		var w = window.innerWidth;

		if(w < 576){
			window.midiaSize = 'xs';
		}

		if(w >= 576){
			window.midiaSize = 'sm';
		}

		if(w >= 768){
			window.midiaSize = 'md';
		}

		if(w >= 992){
			window.midiaSize = 'lg';
		}

		if(w >= 1200){
			window.midiaSize = 'xl';
		}
	}

	$(window).resize(function(){
		if(jaCalcMidia) return;

		setTimeout(function(){
			jaCalcMidia = false;
		},100);

		verifyMidia();
	})

	return verifyMidia();
})();