(function() {
    var has = localStorage.getItem('termos');
    var popup = document.querySelector('.mensagem__coockies');
    var btn = popup.querySelector('.btn-coockies');
    var date = today();

    if(has) {
        

        has = JSON.parse(has);

        if(has.date === date) {
            popup.parentNode.removeChild(popup);
        }
        
    }
    else {
        popup.classList.add('show')
    }

    popup.addEventListener('click', function(e) {
        e.preventDefault();

        var termos = {
            date: date,
            politica_de_privacidade: 'Li e aceito os termos'
        };

        localStorage.setItem('termos', JSON.stringify(termos));
        
        popup.classList.add('slideOutDown', 'animated');

        popup.addEventListener('animationend', function() {
            popup.parentNode.removeChild(popup);
        }, false)
    });

    function today() {
        var date   = new Date();
        var day    = decimal(date.getDate());
        var month  = decimal(date.getMonth() + 1);
        var year   = date.getFullYear();

        return day+'/'+month+'/'+year;
    }

    function decimal(value) {
        return value < 10 ? '0'+value : value;
    }
})();