function onOwlCarouselReady(){

	$('.owl-carousel').each(function(){
		var responsiveList = {};

		responsiveList.xs = $(this).data('xs') || 1;
		responsiveList.sm = $(this).data('sm') || responsiveList.xs;
		responsiveList.md = $(this).data('md') || responsiveList.sm;
		responsiveList.lg = $(this).data('lg') || responsiveList.ms;
		responsiveList.xl = $(this).data('xl') || responsiveList.lg;

		var childrenLength = $(this).children().length;
		var margin = $(this).data('margin');
		var autoplay = $(this).data('autoplay') == 'true' ? true : false;

		$(this).owlCarousel({
			autoplay: autoplay,
			responsive : {
				0 : {
					items : responsiveList.xs,
					loop  : childrenLength > responsiveList.xs,
					margin: margin || 0
				},
				576 : {
					items : responsiveList.sm,
					loop  : childrenLength > responsiveList.sm,
					margin: margin || 0
				},
				768 : {
					items : responsiveList.md,
					loop  : childrenLength > responsiveList.md,
					margin: margin || 0
				},
				992 : {
					items : responsiveList.lg,
					loop  : childrenLength > responsiveList.lg,
					margin: margin || 0
				},
				1200 : {
					items : responsiveList.xl,
					loop  : childrenLength > responsiveList.xl,
					margin: margin || 0
				},
			}
		});
	});


	$('[data-owl]').click(function(e){
		e.preventDefault();

		var owl = $(this).data('owl');
		var target = $(this).attr('href');

		$(target).trigger(owl+'.owl.carousel');
	});
}